#featured-posts {
  .post-title {
    margin-top: 20px;
    max-width: 520px;

    a {
      color: var(--color-heavy-metal);
      font-family: var(--font-family-bold);
      font-size: 36px;
      line-height: 48px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
    }
  }

  .post-details {
    display: block;
    color: var(--color-heavy-metal);
    font-size: 12px;
    line-height: 16px;
  }

  .post-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 100px;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-top-left-radius: 240px 240px;
      object-fit: cover;
    }
  }

  .post-data {
    position: relative;
    width: 100%;
    background-color: rgba(29, 29, 27, 0.8);
  }

  .post-excerpt {
    display: -webkit-box;
    color: var(--color-white);
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    > p {
      margin-top: 0;
    }

    a {
      display: inline-block;
      color: var(--color-apricot);
      font-family: var(--font-family-bold);
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
    }
  }

  .more-articles-button {
    display: inline-block;
    margin: 100px 0 100px 0;
    color: var(--color-heavy-metal);
    font-family: var(--font-family-bold);
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-transform: lowercase;
  }
}

@media screen and (max-width: 991px) {
  #featured-posts {
    .post-data {
      padding: 50px;
    }
  }
}

@media screen and (min-width: 992px) {
  #featured-posts {
    .post-content {
      margin-right: 20px;
    }

    .post-data {
      padding: 90px 110px;
    }
  }
}

@media screen and (min-width: 1200px) {
  #featured-posts {
    .more-articles-button {
      margin-left: 100px;
    }
  }
}
