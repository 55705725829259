.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px);

  img {
    margin-bottom: 20px;
    width: 100px;
  }
}
