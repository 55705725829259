#tag {
  h1 {
    color: var(--color-heavy-metal);
    font-family: var(--font-family-bold);
    font-size: 37px;
    line-height: 48px;
  }

  .tags {
    margin-bottom: 50px;
  }

  .tag {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 20px;
    padding: 0 6px;
    color: var(--color-heavy-metal);
    background-color: var(--color-silver);
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }

  .posts {
    margin-bottom: 100px;

    h2 {
      color: var(--color-heavy-metal);
      font-family: var(--font-family-bold);
      font-size: 20px;
      line-height: 26px;
    }

    ul {
      margin-bottom: 40px;
    }

    a {
      display: inline-block;
      color: var(--color-apricot);
      font-family: var(--font-family-bold);
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
    }
  }
}
