#blog {
  #blog-intro {
    position: relative;
    min-height: 530px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 29, 27, 0.5);
    border-bottom-right-radius: 240px 240px;
    z-index: 1;
  }

  .intro-background {
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 240px 240px;
    z-index: 0;
    object-fit: cover;
  }

  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 90px;
    min-height: 530px;
    z-index: 2;

    h2 {
      color: var(--color-white);
      font-family: var(--font-family-bold);
      letter-spacing: 0.02em;
    }

    p {
      margin: 0;
      color: var(--color-apricot);
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 991px) {
  #blog {
    .overlay,
    .intro-background {
      left: 0;
    }

    .box {
      padding-left: 20px;
      padding-right: 20px;

      h2 {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  #blog {
    .overlay,
    .intro-background {
      right: 140px;
    }

    .box {
      h2 {
        font-size: 65px;
        line-height: 84px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  #blog {
    .box {
      max-width: 700px;
    }
  }
}

@media screen and (min-width: 1200px) {
  #blog {
    .box {
      max-width: 900px;
    }
  }
}
