#office {
  margin-bottom: 120px;

  h2 {
    margin-top: 0;
    margin-bottom: 120px;
    padding: 20px;
    color: var(--color-white);
    background-color: var(--color-heavy-metal);
    text-align: center;
    font-family: var(--font-family-bold);
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.3em;
    text-transform: lowercase;
  }

  .office-content {
    border-bottom-right-radius: 240px 240px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .office-description {
      background-color: var(--color-black);
      letter-spacing: 0em;
    }

    a {
      display: inline-block;
      color: var(--color-apricot);
      font-family: var(--font-family-bold);
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
    }

    p {
      color: var(--color-white);
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 1199px) {
  #office {
    .office-content {
      .office-description {
        padding: 50px 50px 80px 50px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #office {
    .office-content {
      .col-xl-6 {
        &:first-child {
          padding-right: 0;
        }

        &:last-child {
          padding-left: 0;
        }
      }

      .office-description {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 80px;
        min-height: 650px;
      }
    }
  }
}
