@font-face {
  font-family: 'IBM Plex Mono Light';
  src: url(./../../assets/fonts/IBMPlexMono/IBMPlexMono-Light.ttf)
    format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Bold';
  src: url(./../../assets/fonts/IBMPlexMono/IBMPlexMono-Bold.ttf)
    format('truetype');
  font-display: swap;
}

:root {
  font-family: 'IBM Plex Mono Light', sans-serif;

  --font-family-bold: 'IBM Plex Mono Bold', sans-serif;
}
