:root {
  --color-black: #000;
  --color-heavy-metal: #1d1d1b;
  --color-silver: #aeaeae;
  --color-white: #fff;
  --color-red: #ff0000;
  --color-apricot: #e99363;
  --color-jordy-blue: #92d4f2;
}

@font-face {
  font-family: "IBM Plex Mono Light";
  src: url(./../../assets/fonts/IBMPlexMono/IBMPlexMono-Light.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Bold";
  src: url(./../../assets/fonts/IBMPlexMono/IBMPlexMono-Bold.ttf) format("truetype");
  font-display: swap;
}
:root {
  font-family: "IBM Plex Mono Light", sans-serif;
  --font-family-bold: "IBM Plex Mono Bold", sans-serif;
}

* {
  outline: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
}

main {
  margin-top: 70px;
}

a {
  text-decoration: none;
}

hr {
  display: inline-block;
  width: 100%;
  border-color: var(--color-black);
}

noscript .no-js {
  position: fixed;
  padding: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
}
noscript > img {
  display: none;
  width: 1px;
  height: 1px;
}
noscript > iframe {
  display: none;
  width: 0;
  height: 0;
  visibility: hidden;
}

.page-home .navbar .navbar-brand {
  display: none;
}

.page-title-hidden {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 100%;
}

.container-fluid {
  max-width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.col {
  flex: 1 0 0%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-min-600 {
  min-height: 600px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-100 {
  margin-bottom: 100px;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mr-100 {
  margin-right: 100px;
}

.line-w-small {
  max-width: 72px;
}

.line-w-large {
  max-width: 540px;
}

.line-w-full {
  max-width: 100%;
}

.page-background {
  padding-top: 90px;
  padding-bottom: 90px;
  background: url(./../../assets/images/minified/background.png) no-repeat 100%;
}

.read-more-button {
  display: inline-block;
  margin-top: 20px;
  color: var(--color-apricot);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 110px 0 0 0;
}
.pagination-container .pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.pagination-container .pagination li {
  margin: 0 4px;
  background-color: var(--color-silver);
}
.pagination-container .pagination li.selected {
  background-color: var(--color-heavy-metal);
}
.pagination-container .pagination a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: var(--color-white);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
}

.underline {
  display: inline-block;
}
.underline::before {
  content: "_";
}

.white-right-arrow {
  position: absolute;
  top: 20px;
  left: -20px;
  width: 20px;
  height: 20px;
  background: url(./../../assets/images/icons/white-right-arrow.svg);
}

.black-left-arrow {
  margin-right: 30px;
  width: 30px;
  height: 30px;
  background: url(./../../assets/images/icons/black-left-arrow.svg);
}

.black-right-arrow {
  margin-left: 30px;
  width: 30px;
  height: 30px;
  background: url(./../../assets/images/icons/black-right-arrow.svg);
}

@media screen and (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}
@media screen and (min-width: 576px) {
  .contanier {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
@media screen and (min-width: 1700px) {
  .container {
    max-width: 1640px;
  }
}
article .intro {
  position: relative;
  min-height: 530px;
}
article .intro-background {
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 240px 240px;
  object-fit: contain;
}
article .post-background {
  padding-top: 90px;
  padding-bottom: 90px;
  background: url(./../../assets/images/minified/post-background.png) no-repeat;
  background-position: top -570px left;
}

@media screen and (max-width: 991px) {
  article .intro-background {
    left: 0;
  }
}
@media screen and (min-width: 992px) {
  article .intro-background {
    right: 140px;
  }
}
.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px);
}
.page-not-found img {
  margin-bottom: 20px;
  width: 100px;
}

#blog #blog-intro {
  position: relative;
  min-height: 530px;
}
#blog .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 27, 0.5);
  border-bottom-right-radius: 240px 240px;
  z-index: 1;
}
#blog .intro-background {
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 240px 240px;
  z-index: 0;
  object-fit: cover;
}
#blog .box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 90px;
  min-height: 530px;
  z-index: 2;
}
#blog .box h2 {
  color: var(--color-white);
  font-family: var(--font-family-bold);
  letter-spacing: 0.02em;
}
#blog .box p {
  margin: 0;
  color: var(--color-apricot);
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 991px) {
  #blog .overlay,
  #blog .intro-background {
    left: 0;
  }
  #blog .box {
    padding-left: 20px;
    padding-right: 20px;
  }
  #blog .box h2 {
    font-size: 32px;
    line-height: 42px;
  }
}
@media screen and (min-width: 992px) {
  #blog .overlay,
  #blog .intro-background {
    right: 140px;
  }
  #blog .box h2 {
    font-size: 65px;
    line-height: 84px;
  }
}
@media screen and (max-width: 1199px) {
  #blog .box {
    max-width: 700px;
  }
}
@media screen and (min-width: 1200px) {
  #blog .box {
    max-width: 900px;
  }
}
#collect-tags h3 {
  margin-bottom: 50px;
  font-family: var(--font-family-bold);
  text-transform: lowercase;
}
#collect-tags a {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 20px;
  padding: 0 6px;
  color: var(--color-heavy-metal);
  background-color: var(--color-silver);
  font-size: 14px;
  line-height: 24px;
}

@media screen and (max-width: 767px) {
  #collect-tags h3 {
    margin-top: 100px;
  }
}
@media screen and (min-width: 768px) {
  #collect-tags h3 {
    margin-top: 20px;
  }
}
#contact {
  margin-bottom: 120px;
}
#contact h2 {
  margin-top: 0;
  margin-bottom: 120px;
  padding: 20px;
  color: var(--color-white);
  background-color: var(--color-heavy-metal);
  text-align: center;
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3em;
  text-transform: lowercase;
}
#contact .map {
  width: 100%;
  height: 400px;
  filter: grayscale(100%);
}
#contact .first-col {
  width: 100px;
  color: var(--color-heavy-metal);
  font-size: 18px;
  line-height: 24px;
  text-transform: lowercase;
}
#contact .last-col {
  width: 300px;
}
#contact .last-col a {
  color: var(--color-heavy-metal);
  font-size: 18px;
  line-height: 24px;
}
#contact .send-message-button {
  display: inline-block;
  padding: 18px 26px;
  color: var(--color-apricot);
  background-color: var(--color-heavy-metal);
  border-radius: 12px;
  font-family: var(--font-family-bold);
  font-size: 16px;
  line-height: 20px;
  text-transform: lowercase;
}

@media screen and (max-width: 767px) {
  #contact .row {
    flex-direction: column;
  }
  #contact .contact-details > div:first-child {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #contact .contact-details > div:last-child {
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  #contact .contact-details {
    margin-left: 50px;
  }
}
#copyright-notice {
  display: flex;
  flex-direction: row;
  color: var(--color-white);
  background-color: var(--color-silver);
}

#disclaimer {
  display: flex;
  flex-direction: row;
  color: var(--color-white);
  background-color: var(--color-silver);
}

#featured-posts .post-title {
  margin-top: 20px;
  max-width: 520px;
}
#featured-posts .post-title a {
  color: var(--color-heavy-metal);
  font-family: var(--font-family-bold);
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
#featured-posts .post-details {
  display: block;
  color: var(--color-heavy-metal);
  font-size: 12px;
  line-height: 16px;
}
#featured-posts .post-content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 100px;
  overflow: hidden;
}
#featured-posts .post-content img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-top-left-radius: 240px 240px;
  object-fit: cover;
}
#featured-posts .post-data {
  position: relative;
  width: 100%;
  background-color: rgba(29, 29, 27, 0.8);
}
#featured-posts .post-excerpt {
  display: -webkit-box;
  color: var(--color-white);
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
#featured-posts .post-excerpt > p {
  margin-top: 0;
}
#featured-posts .post-excerpt a {
  display: inline-block;
  color: var(--color-apricot);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}
#featured-posts .more-articles-button {
  display: inline-block;
  margin: 100px 0 100px 0;
  color: var(--color-heavy-metal);
  font-family: var(--font-family-bold);
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-transform: lowercase;
}

@media screen and (max-width: 991px) {
  #featured-posts .post-data {
    padding: 50px;
  }
}
@media screen and (min-width: 992px) {
  #featured-posts .post-content {
    margin-right: 20px;
  }
  #featured-posts .post-data {
    padding: 90px 110px;
  }
}
@media screen and (min-width: 1200px) {
  #featured-posts .more-articles-button {
    margin-left: 100px;
  }
}
.footer {
  height: 90px;
  color: var(--color-white);
  background-color: var(--color-black);
}
.footer .copyright {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  line-height: 18px;
  text-transform: lowercase;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

#intro {
  position: relative;
}
#intro .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 27, 0.56);
  border-bottom-right-radius: 240px 240px;
  z-index: 1;
}
#intro .intro-background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 240px 240px;
  z-index: 0;
  object-fit: cover;
}
#intro .container > div {
  position: relative;
  z-index: 2;
}
#intro .logo-box {
  display: inline-block;
  margin-left: -80px;
}
#intro .logo {
  width: 236px;
  height: 236px;
}
#intro .intro-description {
  max-width: 693px;
  color: var(--color-apricot);
  font-family: var(--font-family-bold);
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  #intro .intro-description {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  #intro .intro-description {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 1199px) {
  #intro {
    min-height: 50vh;
  }
  #intro .container > div {
    padding: 20px 20px 80px 20px;
    min-height: 50vh;
  }
}
@media screen and (min-width: 1200px) {
  #intro {
    min-height: 100vh;
  }
  #intro .intro-background {
    position: absolute;
    left: 0;
    right: 140px;
    top: 0;
    bottom: 0;
    padding: 0;
  }
  #intro .container > div {
    min-height: 100vh;
  }
  #intro .logo-box {
    position: absolute;
    top: 50px;
  }
  #intro .intro-description {
    margin-left: 10%;
    font-size: 30px;
    line-height: 43px;
  }
}
.navbar {
  display: flex;
  flex-direction: row;
  height: 70px;
}
.navbar .nav {
  flex: 1;
  height: 70px;
  background-color: var(--color-silver);
}
.navbar .navbar-brand {
  display: block;
  width: 35px;
  height: 35px;
}
.navbar .menu {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}
.navbar .menu-item {
  margin-left: 14px;
  margin-right: 14px;
}
.navbar .menu-link {
  padding: 4px;
  color: var(--color-white);
  font-size: 18px;
  line-height: 24px;
  text-transform: lowercase;
}
.navbar .nav-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 140px;
  background-color: var(--color-white);
}
.navbar .language-switcher a,
.navbar .mobile-language-switcher a {
  color: var(--color-red);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 24px;
}
.navbar .mobile-menu-checkbox {
  display: none;
}
.navbar .mobile-menu-checkbox:checked ~ .mobile-menu {
  display: block;
}
.navbar .mobile-menu-toggler {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.navbar .mobile-menu-toggler span {
  top: 24px;
  left: 10px;
}
.navbar .mobile-menu-toggler span::before {
  top: -10px;
}
.navbar .mobile-menu-toggler span::after {
  bottom: -10px;
}
.navbar .mobile-menu-toggler span::before, .navbar .mobile-menu-toggler span::after {
  content: "";
}
.navbar .mobile-menu-toggler span,
.navbar .mobile-menu-toggler span::before,
.navbar .mobile-menu-toggler span::after {
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: black;
  transition: all 0.3s ease-in-out;
}
.navbar .mobile-menu-toggler.active span {
  background-color: transparent;
}
.navbar .mobile-menu-toggler.active span::before, .navbar .mobile-menu-toggler.active span::after {
  top: 0;
  background-color: black;
}
.navbar .mobile-menu-toggler.active span::before {
  transform: rotate(45deg);
}
.navbar .mobile-menu-toggler.active span::after {
  transform: rotate(-45deg);
}
.navbar .mobile-menu {
  display: none;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: var(--color-white);
}
.navbar .mobile-menu-item {
  padding: 12px;
  text-align: center;
}
.navbar .mobile-menu-link {
  color: var(--color-black);
  font-size: 18px;
  line-height: 24px;
  text-transform: lowercase;
}

@media screen and (min-width: 992px) {
  .navbar .mobile {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .navbar .desktop,
  .navbar .language-switcher {
    display: none;
  }
}
#office {
  margin-bottom: 120px;
}
#office h2 {
  margin-top: 0;
  margin-bottom: 120px;
  padding: 20px;
  color: var(--color-white);
  background-color: var(--color-heavy-metal);
  text-align: center;
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3em;
  text-transform: lowercase;
}
#office .office-content {
  border-bottom-right-radius: 240px 240px;
  overflow: hidden;
}
#office .office-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#office .office-content .office-description {
  background-color: var(--color-black);
  letter-spacing: 0em;
}
#office .office-content a {
  display: inline-block;
  color: var(--color-apricot);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}
#office .office-content p {
  color: var(--color-white);
  font-size: 18px;
  line-height: 28px;
}

@media screen and (max-width: 1199px) {
  #office .office-content .office-description {
    padding: 50px 50px 80px 50px;
  }
}
@media screen and (min-width: 1200px) {
  #office .office-content .col-xl-6:first-child {
    padding-right: 0;
  }
  #office .office-content .col-xl-6:last-child {
    padding-left: 0;
  }
  #office .office-content .office-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 80px;
    min-height: 650px;
  }
}
#post h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 520px;
  color: var(--color-heavy-metal);
  font-family: var(--font-family-bold);
  font-size: 34px;
  line-height: 48px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
#post .post-details {
  display: block;
  color: var(--color-heavy-metal);
  font-size: 12px;
  line-height: 16px;
}
#post .post-content {
  margin-top: 50px;
  font-size: 16px;
  line-height: 24px;
}
#post .post-content a {
  display: inline-block;
  color: var(--color-apricot);
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
}
#post .highlighted {
  margin-bottom: 100px;
  font-family: var(--font-family-bold);
}
#post .back-button {
  display: inline-block;
  margin-top: 100px;
  margin-bottom: 100px;
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-transform: lowercase;
  cursor: pointer;
}
#post img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  object-fit: contain;
}
#post .post-content img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 12px;
}

#posts {
  margin-bottom: 80px;
}
#posts .border-top {
  margin-top: 80px;
  padding-top: 90px;
  border-top: 2px solid var(--color-black);
}
#posts .post-title {
  margin-top: 20px;
  max-width: 520px;
}
#posts .post-title a {
  color: var(--color-heavy-metal);
  font-family: var(--font-family-bold);
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
#posts .post-details {
  display: block;
  color: var(--color-heavy-metal);
  font-size: 12px;
  line-height: 16px;
}
#posts .post-content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 100px;
  overflow: hidden;
}
#posts .post-content img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#posts .post-data {
  position: relative;
  width: 100%;
  background-color: rgba(29, 29, 27, 0.8);
  border-top-right-radius: 150px 150px;
}
#posts .post-excerpt {
  display: -webkit-box;
  color: var(--color-white);
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
#posts .post-excerpt > p {
  margin-top: 0;
}
#posts .post-excerpt a {
  display: inline-block;
  color: var(--color-apricot);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  #posts .post-data {
    padding: 50px;
  }
}
@media screen and (min-width: 992px) {
  #posts .post-content {
    margin-right: 20px;
  }
  #posts .post-data {
    padding: 90px 110px;
  }
}
@media screen and (min-width: 1200px) {
  #posts .more-articles-button {
    margin-left: 100px;
  }
}
#recent-posts h3 {
  margin-bottom: 50px;
  font-family: var(--font-family-bold);
  text-transform: lowercase;
}
#recent-posts .post-title {
  margin: 0;
}
#recent-posts .post-title a {
  color: var(--color-heavy-metal);
  font-family: var(--font-family-bold);
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.01em;
}
#recent-posts .post-details {
  display: block;
  margin-bottom: 35px;
  color: var(--color-heavy-metal);
}

@media screen and (min-width: 992px) {
  #recent-posts .post-details {
    font-size: 12px;
    line-height: 16px;
  }
}
@media screen and (max-width: 991px) {
  #recent-posts .post-details {
    font-size: 10px;
    line-height: 14px;
  }
}
#related-posts {
  margin-top: 100px;
  margin-bottom: 200px;
}
#related-posts h2 {
  margin-top: 0;
  margin-bottom: 100px;
  padding: 20px;
  color: var(--color-white);
  background-color: var(--color-heavy-metal);
  text-align: center;
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3em;
  text-transform: lowercase;
}
#related-posts .post-content {
  margin-bottom: 50px;
  background-color: var(--color-silver);
}
#related-posts .post-content a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 380px;
}
#related-posts .post-content h3 {
  display: inline-block;
  margin: 22px 30px;
  color: var(--color-white);
  font-family: var(--font-family-bold);
  line-height: 48px;
}
#related-posts .post-content .caption {
  display: flex;
  height: 80px;
  color: var(--color-apricot);
  background-color: var(--color-heavy-metal);
}
#related-posts .post-content .caption > div {
  padding: 16px;
  font-size: 14px;
  line-height: 18px;
}
#related-posts .post-content .caption > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#related-posts .post-content .caption > div:last-child {
  display: block;
  margin: auto 0;
}
#related-posts .post-content .caption > div:last-child > span:nth-child(2)::before {
  content: "/";
  margin: 0 4px;
}
#related-posts .col:nth-child(2) h3 {
  color: var(--color-heavy-metal);
}

@media screen and (max-width: 767px) {
  #related-posts .post-content h3 {
    font-size: 28px;
  }
}
@media screen and (min-width: 768px) {
  #related-posts .post-content h3 {
    font-size: 36px;
  }
}
#scroll-to-top {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
#scroll-to-top a {
  display: inline-block;
}

#tag h1 {
  color: var(--color-heavy-metal);
  font-family: var(--font-family-bold);
  font-size: 37px;
  line-height: 48px;
}
#tag .tags {
  margin-bottom: 50px;
}
#tag .tag {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 20px;
  padding: 0 6px;
  color: var(--color-heavy-metal);
  background-color: var(--color-silver);
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
#tag .posts {
  margin-bottom: 100px;
}
#tag .posts h2 {
  color: var(--color-heavy-metal);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
}
#tag .posts ul {
  margin-bottom: 40px;
}
#tag .posts a {
  display: inline-block;
  color: var(--color-apricot);
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}

#under-construction {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url(./../../assets/images/minified/bg.jpg) no-repeat fixed center/cover;
}
#under-construction .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
}
#under-construction a {
  color: #fff;
}
#under-construction p {
  margin-top: 0;
  margin-bottom: 6px;
  color: #fff;
  line-height: 43px;
}
#under-construction .box {
  position: absolute;
}
#under-construction .under-construction p {
  margin-left: -20px;
}
#under-construction .first-col {
  width: 300px;
}
#under-construction .last-col {
  width: 480px;
}

@media screen and (min-width: 1600px) {
  #under-construction .box {
    left: 37%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1599px) {
  #under-construction .box {
    left: 30%;
  }
}
@media screen and (min-width: 1200px) {
  #under-construction p {
    font-size: 33px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  #under-construction p {
    font-size: 24px;
  }
  #under-construction .under-construction p {
    margin-left: -14px;
  }
}
@media screen and (min-width: 992px) {
  #under-construction .box {
    bottom: 94px;
  }
  #under-construction .under-construction {
    margin-bottom: 108px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #under-construction p {
    font-size: 18px;
  }
  #under-construction .box {
    bottom: 94px;
  }
  #under-construction .under-construction {
    margin-bottom: 27px;
  }
  #under-construction .under-construction p {
    margin-left: -10px;
  }
}
@media screen and (min-width: 768px) {
  #under-construction .overlay {
    margin: 95px 100px;
    overflow: hidden;
  }
  #under-construction .logo-box {
    position: relative;
    top: 100px;
    left: 100px;
  }
  #under-construction .box {
    bottom: 20px;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  #under-construction .overlay {
    overflow: auto;
  }
  #under-construction .logo-box {
    display: block;
    margin-top: 100px;
    text-align: center;
  }
  #under-construction .box {
    right: 0;
    left: 0;
    padding: 40px 20px;
  }
  #under-construction p {
    font-size: 16px;
  }
  #under-construction .under-construction {
    margin-bottom: 54px;
  }
  #under-construction .under-construction p {
    margin-left: -10px;
  }
}