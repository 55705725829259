:root {
  // Black
  --color-black: #000;
  --color-heavy-metal: #1d1d1b;

  // Silver
  --color-silver: #aeaeae;

  // White
  --color-white: #fff;

  // Red
  --color-red: #ff0000;
  --color-apricot: #e99363;

  // Blue
  --color-jordy-blue: #92d4f2;
}
