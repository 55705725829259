#collect-tags {
  h3 {
    margin-bottom: 50px;
    font-family: var(--font-family-bold);
    text-transform: lowercase;
  }

  a {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 20px;
    padding: 0 6px;
    color: var(--color-heavy-metal);
    background-color: var(--color-silver);
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  #collect-tags {
    h3 {
      margin-top: 100px;
    }
  }
}

@media screen and (min-width: 768px) {
  #collect-tags {
    h3 {
      margin-top: 20px;
    }
  }
}
